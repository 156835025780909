import styles from './AppNavigation.module.scss'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Logo from '@/assets/svg/logo.svg'
import Button from '@/components/Button'
import UserService from '@/services/UserService'
import renderCustomIcon from '@/utils/renderCustomIcon'
import slugify from '@/utils/slugify'

/*
  The pattern used for this component is called the Tree View Pattern. More information can be found here:
  * https://www.w3.org/WAI/ARIA/apg/patterns/treeview/examples/treeview-navigation/
*/
function AppNavigation({ menuItems, className, ...props }) {
  const router = useRouter()
  const menus = menuItems.filter((item) => item.childItems.nodes.length > 0)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [userUrl, setUserUrl] = useState('/inloggen')
  const MagnifiyingIcon = renderCustomIcon('magnifying-glass')
  const UserIcon = renderCustomIcon('profile')
  const HamburgerIcon = renderCustomIcon('hamburger')

  useEffect(() => {
    function toggleIsExpanded() {
      const mql = window.matchMedia('(max-width: 1023px)')
      setIsExpanded(!mql.matches)
    }

    toggleIsExpanded()

    window.addEventListener('resize', toggleIsExpanded)
    return () => window.removeEventListener('resize', toggleIsExpanded)
  }, [])

  useEffect(() => {
    const Cookie = document.cookie
    const userShowResponse = async () => await UserService().show({ Cookie })
    userShowResponse().then((res) => {
      if (res.status === 200) {
        setUserUrl('/mijn-bk/persoonlijke-gegevens')
      }
    })
  }, [router.events])

  function toggleMenu() {
    setMenuIsOpen((prev) => !prev)
  }

  useEffect(() => setMenuIsOpen(false), [router])

  return (
    <nav
      className={cn(styles['root'], { [className]: className })}
      data-is-open={menuIsOpen}
      {...props}
    >
      <div className={styles['menubar']}>
        <Link
          title="Brancheorganisatie Kinderopvang"
          aria-label="Brancheorganisatie Kinderopvang"
          href="/"
          className={styles['logo']}
        >
          <Logo />
        </Link>

        <ul className={styles['menu-items']}>
          <li>
            <Link
              title="Zoeken"
              aria-label="Zoeken"
              href="/zoeken"
            >
              <MagnifiyingIcon />
            </Link>
          </li>
          <li>
            <Link
              title="Account"
              aria-label="Account"
              href={userUrl}
            >
              <UserIcon />
            </Link>
          </li>
          <li>
            <button
              type="button"
              onClick={toggleMenu}
              name="Menu knop"
              aria-label={menuIsOpen ? 'Close menu' : 'Open menu'}
              aria-expanded={menuIsOpen} // Reflects the current state of the menu
              className={cn(styles['hamburger-button'])}
            >
              <HamburgerIcon />
              <XMarkIcon />
            </button>
          </li>
        </ul>
      </div>

      <div className={cn(styles['dropdown'])}>
        <ul role="tree">
          {menus.map((menu) => {
            const MenuIcon = renderCustomIcon(menu.menuAcf.icon)

            return (
              <li
                key={menu.label}
                role="none"
              >
                <Link
                  role="treeitem"
                  aria-expanded={isExpanded}
                  aria-owns={`id-${slugify(menu.label)}-subtree`}
                  href={menu.uri}
                  rel={menu.linkRelationship}
                  className={cn({ ['lg:pointer-events-none']: menu.uri === '#' })}
                  onClick={(event) => toggleExpanded(event)}
                >
                  <div>
                    {MenuIcon && <MenuIcon />}
                    <span>{menu.label}</span>
                  </div>

                  <ChevronDownIcon className={styles['chevron']} />
                </Link>

                <ul
                  id={`id-${slugify(menu.label)}-subtree`}
                  role="group"
                  aria-label={menu.label}
                >
                  {menu.childItems.nodes.map((item) => {
                    const isActive = router.asPath.startsWith(item.uri.slice(0, -1))

                    return (
                      <li
                        key={item.label}
                        role="none"
                      >
                        <Link
                          role="treeitem"
                          target={item.target}
                          href={item.uri}
                          rel={item.linkRelationship}
                          className={cn({ [styles['active']]: isActive })}
                        >
                          <span>{item.label}</span>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })}
        </ul>

        <div className={styles['buttons']}>
          <Button
            outline
            href="/helpdesk"
            color="white-yellow"
          >
            Helpdesk
          </Button>

          <Button
            outline
            href="/lid-worden"
            color="white-yellow"
          >
            Lid worden
          </Button>
        </div>
      </div>
    </nav>
  )
}

function toggleExpanded(event) {
  const treeitem = event.currentTarget

  isExpanded(treeitem) ? collapseTreeitem(treeitem) : expandTreeitem(treeitem)

  event.preventDefault()
  event.stopPropagation()
}

function collapseTreeitem(treeitem) {
  if (!treeitem.getAttribute('aria-owns')) return

  const groupNode = getGroupNode(treeitem)
  if (!groupNode) return
  treeitem.setAttribute('aria-expanded', 'false')
}

/*
  As an enhancement on top of the pattern I've added a way to conditionally enable/disable the option to have a
  single item opened
*/
function expandTreeitem(treeitem, singleOpen = true) {
  if (singleOpen) collapseAllTreeitems()

  if (!treeitem.getAttribute('aria-owns')) return

  const groupNode = getGroupNode(treeitem)
  if (!groupNode) return
  treeitem.setAttribute('aria-expanded', 'true')
}

function collapseAllTreeitems() {
  return document
    .querySelectorAll('[role="treeitem"][aria-expanded="true"]')
    .forEach((treeitem) => treeitem.setAttribute('aria-expanded', 'false'))
}

function getGroupNode(treeitem) {
  if (!treeitem.getAttribute('aria-owns')) return false
  return document.getElementById(treeitem.getAttribute('aria-owns'))
}

function isExpanded(treeitem) {
  return treeitem.getAttribute('aria-expanded') === 'true'
}

export default AppNavigation
